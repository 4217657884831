//import ant design components
import { Upload, Button, Input } from 'antd';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import ConfigurationManager from './ConfigurationManager';
import { UploadOutlined } from '@ant-design/icons';

const ImageUploadModal = ({ msalInstance, agentName, tokenManager, disabled, onSaved, mode, visible }) => {               
    const [isVisible, setIsVisible] = useState(visible);
    const [folderName, setFolderName] = useState('');
    const [apiToken, setApiToken] = useState(null);    



    const formData = new FormData();
    const uploadImageDataProps = {
        data: formData,
        action: `${ConfigurationManager.getApiUrl()}Image/Upload`,
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        },
        defaultFileList: [
        ],
    };

    const parseJsonForImages = {
        data: formData,
        action: `${ConfigurationManager.getApiUrl()}Image/ParseJsonForImages`,
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        },
        defaultFileList: [
        ],
    };


    useEffect(() => {
        let isMounted = true;

        const doGetApiToken = async () => {
            try {
                const token = await tokenManager.getAADToken();
                if (isMounted) {
                    setApiToken(token);
                }
            } catch (error) {
                console.error('Error fetching API token:', error);
            }
        };

        // Initial fetch  
        doGetApiToken();

        // Set up interval  
        const interval = setInterval(() => {
            doGetApiToken();
        }, 1000 * 60 * 10); // 10 minutes  

        // Cleanup on unmount  
        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [tokenManager]);  


    return (
        <div>
            <Button type="primary" onClick={() => setIsVisible(true)} disabled={disabled} >
                Manage Images
            </Button>


            <Modal visible={isVisible} title="Manage Images" onOk={() => { setIsVisible(false) }}>
                <label>
                    Folder Name (optional):
                    <Input placeholder="Folder Name" onChange={(e) => setFolderName(e.target.value)} />
                </label>
                <label>

                    <br />
                    <br />
                Images:&nbsp; 
                <Upload {...uploadImageDataProps} headers={{ 'Authorization': 'Bearer ' + apiToken }} data={{ agentName: agentName, subFolderName: folderName }} multiple listType="picture">                    
                    <Button icon={<UploadOutlined />} style={{ marginRight: 10 }}>
                       Upload
                    </Button>                    
                    </Upload>
                </label>
                <br />

                <label>
                Parse JSon For Images:&nbsp;
                    <Upload {...parseJsonForImages} headers={{ 'Authorization': 'Bearer ' + apiToken }} data={{ agentName: agentName, subFolderName: folderName }}>
                    <Button icon={<UploadOutlined />}  style={{ marginRight: 10 }}>
                        Upload
                    </Button>
                    
                </Upload>
                </label>                
            </Modal>
        </div>
    );
};

export default ImageUploadModal;