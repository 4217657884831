import React, { useState, useEffect } from 'react';
import { Drawer, List, Tooltip } from 'antd';

const TaskListPanel = ({ connection }) => {
    const [taskList, setTaskList] = useState(null);

    useEffect(() => {
        if (!connection) return;

        const handleUpdateTaskList = (chatId, newTaskList) => {
            setTaskList(newTaskList);
        };

        const handleDeleteTaskList = (chatId) => {
            setTaskList(null);
        };

        connection.on('UpdateTaskList', handleUpdateTaskList);
        connection.on('DeleteTaskList', handleDeleteTaskList);

        return () => {
            connection.off('UpdateTaskList', handleUpdateTaskList);
            connection.off('DeleteTaskList', handleDeleteTaskList);
        };
    }, [connection]);  

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }).format(date);
    };

    return (
        <Drawer
            title={taskList?.Description || "Task List"}
            placement="right"
            onClose={() => setTaskList(null)}
            open={taskList !== null}  // Changed 'visible' to 'open'  
            autoFocus={false}
        >
            <List
                dataSource={taskList?.Tasks}
                renderItem={task => (
                    <Tooltip
                        title={
                            <div>
                                <p><strong>Due Date:</strong> {formatDate(task.TaskDueDate)}</p>
                                <p><strong>Priority:</strong> {task.TaskPriority}</p>
                                <p><strong>Status:</strong> {task.TaskStatus}</p>
                                <p><strong>Location:</strong> {task.TaskLocation}</p>
                            </div>
                        }
                    >
                        <List.Item key={task.TaskNumber}>
                            {task.TaskNumber} - {task.TaskDescription}
                        </List.Item>
                    </Tooltip>
                )}
            />
            {
                taskList !== null && taskList?.Outcome && (
                    <p>
                        <strong>Outcome:</strong> {taskList.Outcome}
                    </p>
                )
            }
        </Drawer>
    );
};

export default TaskListPanel;  