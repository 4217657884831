// ToolConfigPanel.js  
import { Input, Collapse, Avatar, Form, Checkbox, InputNumber, Select } from 'antd';
import React from 'react';
import get from 'lodash/get';
const { TextArea } = Input;
const { Option } = Select;

const ToolConfigPanel = React.memo(function ToolConfigPanel({
    title,
    toolKey,
    tool,
    handleChange,
    handleToggle,
    handleNumberChange,
    handleModelChange,
    handleSelectChange,
    inferenceOptions,
    inputRefs,
    depth = 0,
    hideDisabledTools
}) {
    if (hideDisabledTools && tool.Disabled) {
        return null; // Do not render this tool  
    }  

    const header = (
        <span>
            {tool.IconUrl && <Avatar src={tool.IconUrl} style={{ marginRight: 8, height:80, width:80 }} />}
            {title}
        </span>
    );

    return (
        <Collapse header={header}
            defaultActiveKey={[tool.id || toolKey]}
            expandIconPosition="end"
            style={{ marginLeft: depth * 20 }}
        >
            <Collapse.Panel header={header} key={tool.id || toolKey}>
                {/*<Space direction="vertical" size="small" style={{ display: 'flex' }}>*/}

                {/* Render description if available */}
                {tool.Description && (
                    <p>{tool.Description}</p>
                )}
                {/* Render controls */}
                {tool.DesigntimeControlConfig &&
                    [...tool.DesigntimeControlConfig]
                        .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
                        .map((designTimeControlConfig, i) => (
                            <div key={designTimeControlConfig.Name}>
                                {!designTimeControlConfig.Invisible &&
                                    renderControl(designTimeControlConfig, toolKey, tool, inputRefs, {
                                        handleChange,
                                        handleToggle,
                                        handleNumberChange,
                                        handleModelChange,
                                        handleSelectChange,
                                        inferenceOptions,
                                    })}
                            </div>
                        ))}
                {/* Recursively render child tools */}
                {tool.ChildTools && tool.ChildTools.length > 0 && (
                    <div style={{ marginLeft: depth * 20 }}>
                        {tool.ChildTools.map((childTool, index) => (
                            <ToolConfigPanel
                                key={childTool.id || `${toolKey}-${index}`}
                                toolKey={`${toolKey}.ChildTools[${index}]`}
                                tool={childTool}
                                title={childTool.name || `Tool ${index + 1}`}
                                handleChange={handleChange}
                                handleToggle={handleToggle}
                                handleNumberChange={handleNumberChange}
                                handleModelChange={handleModelChange}
                                inferenceOptions={inferenceOptions}
                                inputRefs={inputRefs}
                                handleSelectChange={handleSelectChange}
                                depth={depth + 1}
                            />
                        ))}
                    </div>
                )}
                {/*  </Space>*/}
            </Collapse.Panel>
        </Collapse>
    );
});

function renderControl(designTimeControlConfig, keyPath, tool, inputRefs, handlers) {
    const name = `${keyPath}.${designTimeControlConfig.Name}`;
    switch (designTimeControlConfig.Type) {
        case "MultilineEdit":
            return (
                <Form.Item label={designTimeControlConfig.Text}>
                    <TextArea
                        ref={(ref) => inputRefs.current[name] = ref}
                        name={name}
                        value={get(tool, designTimeControlConfig.Name)}
                        onChange={handlers.handleChange}
                        rows={4}
                    />
                </Form.Item>
            );
        case "Checkbox":
            return (
                <Form.Item>
                    <Checkbox
                        name={name}
                        checked={get(tool, designTimeControlConfig.Name)}
                        onChange={handlers.handleToggle}
                    >
                        {designTimeControlConfig.Text}
                    </Checkbox>
                </Form.Item>
            );
        case "Number":
            return (
                <Form.Item label={designTimeControlConfig.Text}>
                    <InputNumber
                        name={name}
                        value={get(tool, designTimeControlConfig.Name)}
                        min={0}
                        max={1000}
                        onChange={(value) => handlers.handleNumberChange(value, name)}
                    />
                </Form.Item>
            );
        case "SinglelineEdit":
            return (
                <Form.Item label={designTimeControlConfig.Text}>
                    <Input
                        name={name}
                        value={get(tool, designTimeControlConfig.Name)}
                        onChange={handlers.handleChange}
                    />
                </Form.Item>
            );
        case "InferenceModelDropList":
            return (
                <Form.Item label={designTimeControlConfig.Text} key={name}>
                    <Select
                        name={name}
                        options={handlers.inferenceOptions}
                        value={get(tool, designTimeControlConfig.Name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleModelChange(value, name)}
                    />
                </Form.Item>
            );
        case "SearchMode":
            return (
                <Form.Item label={designTimeControlConfig.Text}>
                    <Select
                        name={name}
                        value={get(tool, designTimeControlConfig.Name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleSelectChange(value, name)}
                        defaultValue="Any"
                    >
                        <Option value="All">All</Option>
                        <Option value="Any">Any</Option>
                    </Select>
                </Form.Item>
            );
        case "SearchProvider":
            return (
                <Form.Item label={designTimeControlConfig.Text}>
                    <Select
                        name={name}
                        value={get(tool, designTimeControlConfig.Name)}
                        style={{ width: 200 }}
                        onChange={(value) => handlers.handleSelectChange(value, name)}
                    >
                        <Option value="Cognitive">Cognitive Search</Option>
                        <Option value="Elastic">Elastic Search</Option>
                    </Select>
                </Form.Item>
            );
        default:
            return null;
    }
}

export default ToolConfigPanel;  