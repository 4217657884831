import React, { useState, useEffect, useCallback } from 'react';
import { Input, Upload, Select, Button, Form, Spin, Modal, Space } from 'antd';

import { sendAgentUpdate, getAgentDetails, getInferenceOptions } from '../messaging';
import { Checkbox } from '../../node_modules/antd/es/index';
import ADGroupsDropdown from './PickADGroup';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import ToolConfigPanel from './ToolConfigPanel';
import { UploadOutlined } from '@ant-design/icons';
import set from 'lodash/set';

const { TextArea } = Input;


const AgentConfigurator = ({ msalInstance, agentName, tokenManager, disabled, onSaved, onCanceled, mode, visible }) => {
    const [groupOptions, setGroupOptions] = useState([]);
    const [agent, setAgent] = useState(null);
    /*const [agents, setAgents] = useState(null);*/
    const [loading, setLoading] = useState(false);
    //const [hasSystemPrompt, setHasSystemPrompt] = useState(false);
    const [busyMessage, setBusyMessage] = useState("Loading...");
    const [inferenceOptions, setInferenceOptions] = useState(null);
    const inputRefs = React.useRef({});


    const loadAgentForEditing = useCallback(async (tokenManager, agentName, setAgent, setInferenceOptions, setLoading) => {
        const aadToken = await tokenManager.getAADToken();
        getAgentDetails(aadToken, agentName).then((data) => {

            if (mode === 'add') {
                data.Id = null;
            }

            setAgent(data);
        }).then(() => {
            getInferenceOptions(aadToken).then((data) => {
                //transform the string array into options for the ant design select list
                var options = data.map((item) => ({ value: item.model, label: item.model, data: item }));
                setInferenceOptions(options);
            }).then(() => {
                setBusyMessage(null);
            });
        });
    }, [mode]);






    async function handleSave() {
        setBusyMessage("Saving...");

        const aadToken = await tokenManager.getAADToken();

        //for (const prop in agent) {
        //    if (agent[prop] === null) {
        //        agent[prop] = ' ';
        //    } else if (Array.isArray(agent[prop])) {
        //        agent[prop] = agent[prop].map((item) => (item === null ? ' ' : item));
        //    }
        //}

        sendAgentUpdate(aadToken, agent);
        setAgent(null);
        /*setAgents(null);*/

        if (onSaved)
            await onSaved();


        setBusyMessage(null);
    };

    async function handleCancel() {
        setAgent(null);
        /*setAgents(null);*/
        setBusyMessage(null);
        if (onCanceled)
            await onCanceled();
    }

    const getADGroups = useCallback(async () => {
        async function getGraphClient() {
            const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, {
                account: msalInstance.getAllAccounts()[0],
                interactionType: InteractionType.Popup,
                scopes: ['User.Read', "Group.Read.All"],
            });

            const graphClient = Client.initWithMiddleware({ authProvider: authProvider });
            return graphClient;
        }


        if (getGraphClient) {
            const client = await getGraphClient();

            const result = await client.api('/groups')
                .select('displayName,id')
                .filter("startsWith(displayName,'SG-')")
                .get();

            const updatedGroupsOptions = result.value.map((group) => ({
                key: group.id,
                text: group.displayName
            }));
            setGroupOptions(updatedGroupsOptions);
        }
    }, [msalInstance]);


    useEffect(() => {
        if (visible && !loading && agent == null) {
            loadAgentForEditing(tokenManager, agentName, setAgent, setInferenceOptions, setLoading).then(() => {
                getADGroups();
                setLoading(false);
            });
        }
    }, [getADGroups, loadAgentForEditing, loading, agent, agentName, tokenManager, visible])


    useEffect(() => {
        if (visible && !loading && agent == null) {
            setLoading(true);
        }
    }, [getADGroups, loading, agentName, tokenManager, visible, agent]);


    const handleNestedObjectChange = (prevState, nameParts, value) => {
        const [firstKey, secondKey, thirdKey] = nameParts;

        const oldFirstLevelValue = prevState[firstKey] || {};
        const oldSecondLevelValue = oldFirstLevelValue[secondKey] || {};

        const newSecondLevelValue = { ...oldSecondLevelValue, [thirdKey]: value };
        const newFirstLevelValue = { ...oldFirstLevelValue, [secondKey]: newSecondLevelValue };

        return { ...prevState, [firstKey]: newFirstLevelValue };
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');


        // Save current cursor position  
        const cursorPosition = e.target.selectionStart;

        if (nameParts.length === 3) {
            setAgent(prevState => handleNestedObjectChange(prevState, nameParts, value));
        }
        else {
            setAgent(prevState => ({
                ...prevState,
                [nameParts[0]]: value
            }));
        }

        if (inputRefs.current[name]) {
            inputRefs.current[name].selectionStart = cursorPosition;
            inputRefs.current[name].selectionEnd = cursorPosition;
        }
    };

    const handleSelectChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleToggle = (e) => {
        const { name, checked } = e.target;
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), checked);
        setAgent(newAgent);
    };

    const handleNumberChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleModelChange = (value, name) => {
        const newAgent = { ...agent };
        set(newAgent, name.replace(/\.\d+\./g, '.'), value);
        setAgent(newAgent);
    };

    const handleAdminGroupsMultiSelectChange = (value, option, options) => {
        setAgent(prevState => ({
            ...prevState,
            AdminGroups: value
        }));
    };

    const handleAccessGroupsMultiSelectChange = (value, option, options) => {
        setAgent(prevState => ({
            ...prevState,
            ApprovedGroups: value
        }));
    };

    function exportToJsonFile() {

        //convert the agent object to a string indented with 4 spaces
        let dataStr = JSON.stringify(agent, null, 4);
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        let exportFileDefaultName = `${agent.Name}.json`;

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }


    const props = {
        name: 'file',
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        headers: {
            authorization: 'authorization-text',
        },

        beforeUpload(file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = () => {
                    var loadedAgent = JSON.parse(reader.result);
                    loadedAgent.Id = null;
                    setAgent(loadedAgent);
                };
            });
        },
    }


    return (
        <div>
            {(visible && agent) &&
                <Modal title={`${agent.Name} - Configuration`} 
                    open={visible}
                    onOk={handleSave}
                    onCancel={handleCancel}
                    okText="Save"
                    cancelText="Cancel" className="agent-configuration" width="80%">

                    {busyMessage && <Spin spinning={!!busyMessage} tip={busyMessage} />}
                    {!busyMessage && agent &&
                        <Form>
                            <Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                {/* Agent details */}
                                {mode === 'add' &&
                                    <label>
                                        Name:
                                        <Input name="Name" value={agent.Name} onChange={handleChange} />
                                    </label>
                                 }
                                <label>
                                    Description:
                                    <Input name="Description" value={agent.Description} onChange={handleChange} />

                                </label>
                                <div>
                                    <label>
                                        Agent Prompt:
                                        <TextArea name="Prompt" value={agent.Prompt} onChange={handleChange} rows={4} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Inference Engine:
                                        <Select name="Inference" value={agent.Inference} options={inferenceOptions} style={{ width: 200 }} onChange={(value) => handleModelChange(value, 'Inference')} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Groups With Admin Access (no selection means all groups have access):
                                        <ADGroupsDropdown name="AdminGroups" onChange={handleAdminGroupsMultiSelectChange} options={groupOptions} value={agent.AdminGroups} />
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        Groups With Access (no selection means all groups have access):
                                        <ADGroupsDropdown name="ApprovedGroups" onChange={handleAccessGroupsMultiSelectChange} options={groupOptions} value={agent.ApprovedGroups} />
                                    </label>
                                </div>
                                <label>
                                    Image Folder Name:
                                    <Input name="ImageFolderName" value={agent.ImageFolderName} onChange={handleChange} />
                                </label>
                                <label>
                                    Welcome Message:
                                    <Input name="WelcomeMessage" value={agent.WelcomeMessage} onChange={handleChange} />
                                </label>
                                <label>
                                    <Checkbox name="HideDisabledTools" checked={agent.HideDisabledTools} onChange={handleToggle}>
                                        Hide Disabled Tools
                                    </Checkbox>  
                                </label>
                                {/* Render tools */}
                                {agent.Tools && Object.entries(agent.Tools).sort((a, b) => parseInt(a[1].DisplayOrder) - parseInt(b[1].DisplayOrder)).map(([toolKey, tool], i) => (
                                    <ToolConfigPanel
                                        title={toolKey}
                                        key={`Tools.${toolKey}`}
                                        toolKey={`Tools.${toolKey}`}
                                        tool={tool}
                                        hideDisabledTools={agent.HideDisabledTools}  
                                        handleChange={handleChange}
                                        handleToggle={handleToggle}
                                        handleNumberChange={handleNumberChange}
                                        handleModelChange={handleModelChange}
                                        handleSelectChange={handleSelectChange}
                                        inferenceOptions={inferenceOptions}
                                        inputRefs={inputRefs}
                                    />
                                ))}


                                <div>
                                    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                        <label>
                                            Show Chat Cost:&nbsp;
                                            <Checkbox name="ShowChatCost" checked={agent.ShowChatCost} onChange={handleToggle} />
                                        </label>
                                        <label>
                                            Show Debug Info:&nbsp;
                                            <Checkbox name="ShowDebugInfo" checked={agent.ShowDebugInfo} onChange={handleToggle} />
                                        </label>
                                    </Space>
                                </div>
                                <div>
                                    <Space direction="horizontal" size="middle" style={{ display: 'flex' }}>
                                        <Button onClick={exportToJsonFile}>Export As JSON</Button>
                                        <Upload {...props}>
                                            <Button icon={<UploadOutlined />}>Upload Json Agent Config</Button>
                                        </Upload>
                                    </Space>
                                </div>
                            </Space>

                        </Form>
                    }

                </Modal>
            }
        </div >
    )
}

export default AgentConfigurator;

