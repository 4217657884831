import axios from 'axios';
import ConfigurationManager from './components/ConfigurationManager';
/*import PlaybackManager from './PlaybackManager';*/


export async function getImagePathForAgent(token, agentName) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/GetImagePathFor?&agentName=${encodeURIComponent(agentName)}`;
        const res = await instance.get(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}
export async function newChat(token, agentName) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/NewConversation?agentName=${encodeURIComponent(agentName)}`;
        const res = await instance.get(url);
        
        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function deleteChat(token, agentName, chatId) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        var url = `/chat/DeleteChat?agentName=${encodeURIComponent(agentName)}&chatid=${chatId}`;
        const res = await instance.delete(url);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function sendMessage(token, agentName, chatId, message, messageImages) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        if (message === null || message === undefined) 
            message = '';

        var reply = await instance.post('/chat/UpdateChat', {
            agentName: agentName,            
            chatId: chatId,
            input: message, 
            images: messageImages
        }, {
            params: {
            }
        }); 
        
        return reply.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

       return;
    }
}

export async function sendAgentUpdate(token, agentData) {
    const realToken = token;
    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 30000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        await instance.post('/agent/Save', agentData);

    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgents(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 60000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/agent/AllCurrent');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getCheckClaims(token) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get('/debugInfo/Claims');

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getAllChats(token, agentName) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetAllChats?agentName=${encodeURIComponent(agentName)}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getConversation(token, chatId, agentName) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/chat/GetExistingConversation?chatId=${chatId}&agentName=${encodeURIComponent(agentName)}`);
        
        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getAgentDetails(aadToken, agentName) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/agent/get?name=${encodeURIComponent(agentName)}`;
        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function getInferenceOptions(aadToken) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/Inference/get`;
        const res = await instance.get(url);

        return (res.data)
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}

export async function triggerImportOfBlobDataFile(aadToken, agentName, searchAgentName) {

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${aadToken}` }
        });

        var url = `/SearchIndex/ImportBlobDataFile?agentName=${encodeURIComponent(agentName)}&searchAgentName=${encodeURIComponent(searchAgentName)}`;
        await instance.get(url);
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}


export async function getInferenceChat(token, debugLogId) {
    const realToken = token;

    try {
        const instance = axios.create({
            baseURL: ConfigurationManager.getApiUrl(),
            timeout: 15000,
            headers: { 'Authorization': `Bearer ${realToken}` }
        });

        const res = await instance.get(`/DebugInfo/Inference?id=${debugLogId}`);

        return res.data;
    } catch (err) {
        console.error(err.message);

        if (err.response != null && err.response.data != null)
            console.log(err.response.data);

        return;
    }
}