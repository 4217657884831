import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import TokenManager from './TokenManager';
import { getAgents, getAllChats, getConversation, getAgentDetails, triggerImportOfBlobDataFile, getInferenceChat } from './messaging';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import SydneyChatHub from './SydneyChatHub';
import { Layout, Space, Select, Menu, Tabs, Button, Spin } from 'antd';
/*import { v4 as uuidv4 } from 'uuid';*/
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
/*import { BrowserAuthError } from "@azure/msal-browser";*/
import IndexFileUpload from './components/IndexFileUpload';
/*import Div100vh from 'react-div-100vh'*/
//import type { UploadProps } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined, UserOutlined
} from '@ant-design/icons';
import PlaybackManager from './PlaybackManager';
import ConfigurationManager from './components/ConfigurationManager';
import AgentConfigurator from './components/AgentConfigurator';
/*import DownloadFile from './components/DownloadEnrichedFile';*/
import ImageUploadModal from './components/ImageUploadModal';


//import jwt_decode from 'jwt-decode';


const { Header, Sider, Content } = Layout;
const headerStyle = {
    textAlign: 'left',
    color: '#58595B',
    height: 75,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    //lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
    class: "main-content-panel"
};
const siderStyle = {
    textAlign: 'left',
    paddingleft: 10,
    //lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#180A48',
};
//const footerStyle = {
//    textAlign: 'center',
//    color: '#fff',
//    backgroundColor: '#ffffff',
//    minHeight: 120,
//};


const speechsdk = require('microsoft-cognitiveservices-speech-sdk')
const ashleyAccessSecurityGroup = '4417bd63-4c77-4ebd-aaa0-67da1b95d0d3';
const ashleyRootAccessSecurityGroup = '7c0237c5-39d5-4bab-be13-3c383a24960e';
const siderWidth = 300;


export default function App() {
    const [agents, setAgents] = useState([]);
    const [chats, setChats] = useState([]);
    const [currentAgentName, setcurrentAgentName] = useState(null);
    const [loadingMessages, setIsLoadingMessages] = useState(false);
    const [loadingChats, setIsLoadingChats] = useState(false);
    const [loadingAgentList, setLoadingAgentList] = useState(false);
    const [agent, setAgent] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [messages, setMessages] = useState([]);
    const [chatId, setChatId] = useState(null);
    const [aadAuthToken, setAadAuthToken] = useState(null);
    const [userHasAccess, setUserHasAccess] = useState(false);
    const [showDebugInfo, setShowDebugInfo] = useState(false);
    const [userHasRootAccess, setUserHasRootAccess] = useState(false);
    const [costs, setCosts] = useState(null);
    const [editAgentMode, setEditAgentMode] = useState("edit");
    const [tabs, setTabs] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedSearchAgent, setSelectedSearchAgent] = useState(null);
    const [searchToolOptions, setSearchToolOptions] = useState(null);
    const [apiVersion, setApiVersion] = useState(null);

    const addKeywordsProps = useMemo(() => {
        const formData = new FormData();
        formData.append('agentName', currentAgentName);

        return {
            data: formData,
            action: `${ConfigurationManager.getApiUrl()}Enrichment/AddKeywords`,
            onChange({ file, fileList }) {
                if (file.status !== 'uploading') {
                    console.log(file, fileList);
                }
            },
            defaultFileList: [],
        };
    }
        , [currentAgentName]);

    const recognizer = useRef(null);

    const recognizerTokenExpiry = useRef(null);


    const mounted = useRef(false);
    const { instance, accounts } = useMsal();

    const [tokenManager, setTokenManager] = useState(null);

    const [playbackManager, setPlaybackManager] = useState(null);

    const [openDataConfig, setOpenDataConfig] = useState(false);




    const onAuthenticated = useCallback(async () => {

        setLoadingAgentList(true);

        var localTokenManager = new TokenManager(instance, accounts);
        setTokenManager(localTokenManager);

        var token = await localTokenManager.getAADToken();
        setAadAuthToken(token);

        const hasAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, ashleyAccessSecurityGroup);
        setUserHasAccess(hasAccessGroup);

        const hasRootAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, ashleyRootAccessSecurityGroup);
        setUserHasRootAccess(hasRootAccessGroup);

        if (token) {
            const tokenRes = await localTokenManager.getSpeechAPIToken(token);

            if (tokenRes.authToken === null) {
                console.error('FATAL_ERROR: ' + tokenRes.error);


                return;
            }

            loadAgents(token, localTokenManager);
        }

    }, [accounts, instance]);

    useEffect(() => {
        if (agent && agent.Tools) {
            /*var i = 0;*/
            var availableTools = [];

            for (var toolName in agent.Tools) {
                var tool = agent.Tools[toolName];
                if (tool.SearchProvider !== '') {

                    availableTools.push(toolName);
                }
            }

            setSearchToolOptions(availableTools);
            setSelectedSearchAgent("NotUsed");
        }
    }, [agent]);


    useEffect(() => {
        const fetchVersion = async () => {
            try {
                const response = await fetch(`${ConfigurationManager.getApiUrl()}version`);
                if (response.ok) {
                    const version = await response.text();
                    setApiVersion(version);
                }
            }
            catch (error) {
                console.error('Error fetching version: ' + error);
            }
        }

        fetchVersion();
    }, []);


    //function onChosenSearchAgentChanged(value) {
    //    setSelectedSearchAgent(value);
    //}

    useEffect(() => {
        var localCanEdit = agent && agent.CanEdit;

        setCanEdit(localCanEdit);

        async function onClickChat(item) {
            setIsLoadingMessages(true);

            if (tokenManager) {
                const token = await tokenManager.getAADToken();

                setChatId(parseInt(item.key));

                var conversation = getConversation(token, item.key, currentAgentName);
                conversation.then(async (data) => {

                    setMessages([]);
                    for (let i = 0; i < data.length; i++) {
                        let message = data[i];

                        if (message.debugLogId !== null && message.debugLogId !== undefined && message.debugLogId !== "") {
                            const debugData = await getInferenceChat(token, message.debugLogId);
                            setMessages(prev => [...prev, { ...message, debugData: debugData }]);
                        }
                        else {
                            setMessages(prev => [...prev, { ...message }]);
                        }
                    }

                    setIsLoadingMessages(false);
                }).catch(() => { setIsLoadingMessages(false); });
            }
        }

        var tabsDef = [
            {
                key: '1',
                label: `Chats`,
                children: (
                    <Spin size="medium" spinning={loadingChats} className="message-spinner">
                        <Menu items={chats} style={{
                            width: siderWidth - 20, overflowY: 'auto'
                        }} onClick={onClickChat} theme="dark" className="chat-menu"></Menu>
                    </Spin>),
            }];

        if (localCanEdit) {
            async function onDelete() {
                if (window.confirm(`Are you sure you want to delete ${currentAgentName}?`)) {
                    const token = await tokenManager.getAADToken();
                    const response = await fetch(`${ConfigurationManager.getApiUrl()}Agent/Delete?name=${currentAgentName}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        setcurrentAgentName(null);
                        loadAgents(await tokenManager.getAADToken());
                    }
                }
            }


            //add the following tab for users with edit access
            tabsDef.push(
                {
                    key: '2',
                    label: `Config`,
                    children: (
                        <div>
                            <div className="data-configuration-section">
                                {/*<div>*/}
                                {/*    <h3>Pre-Processing Data</h3>*/}
                                {/*    <p>To improve hit rates on search results, you can upload your data and have keywords automatically generated by GPT3.5. You can now download the file and re-upload it in the next section below.</p>*/}
                                {/*    <Upload {...addKeywordsProps} data={{ persona: currentPersonaName }} headers={{ 'Authorization': 'Bearer ' + aadAuthToken }} disabled={!localCanEdit} >*/}
                                {/*        <Button icon={<UploadOutlined />} disabled={!localCanEdit} >Upload</Button>*/}
                                {/*    </Upload>*/}

                                {/*    <DownloadFile agentName={currentPersonaName} tokenManager={tokenManager} disabled={!localCanEdit}></DownloadFile>*/}
                                {/*</div>*/}

                                <div>
                                    {/*<Select value={selectedSearchAgent} onChange={onChosenSearchAgentChanged} style={{ width: 280 }} >                                        */}
                                    {/*    {*/}
                                    {/*        searchAgentOptions && Object.values(searchAgentOptions).map(agent => (*/}
                                    {/*            <option value={agent}>{agent}</option>*/}
                                    {/*        ))*/}
                                    {/*    }*/}
                                    {/*</Select>*/}
                                    <IndexFileUpload tokenManager={tokenManager} agentName={currentAgentName} disabled={!localCanEdit} selectedSearchAgent={selectedSearchAgent} ></IndexFileUpload>

                                    <Button onClick={async () => triggerImportOfBlobDataFile(await tokenManager.getAADToken(), currentAgentName, selectedSearchAgent)} disabled={!localCanEdit || selectedSearchAgent === null}>Import Blob Data</Button>
                                </div>

                                <h3>Data Configuration</h3>

                                <p>You can configure the current agent, changing the prompts used throughout the workflow.</p>

                                <Button type="primary" onClick={() => { setOpenDataConfig(true); setEditAgentMode("edit") }} disabled={!localCanEdit} >
                                    Configure Agent
                                </Button>

                                <h3>Images</h3>
                                <p>In the system, we have the ability to surface images, to prevent CORS errors, we need to upload them here (or get the CORS policy of the host site updated)</p>
                                <ImageUploadModal agentName={currentAgentName} tokenManager={tokenManager} disabled={!localCanEdit} />

                                {userHasRootAccess ? (
                                    <Button onClick={onDelete} >Delete Agent</Button>) : (<></>)}
                            </div>
                        </div>
                    ),
                });

            tabsDef.push({
                key: '3',
                label: 'Costs',
                children: (
                    <div className="costs-section">
                        <h2>Monthly Spend</h2>
                        {
                            costs && Object.entries(costs.ModelCosts).map(([a, cost]) => (
                                <div key={cost.model}>
                                    <h3>{cost.model}</h3>
                                    <div>Agent: ${Number.parseFloat(cost.costForAgent).toFixed(4)}</div>
                                    <div>User: ${Number.parseFloat(cost.costForUser).toFixed(4)}</div>
                                </div>
                            ))
                        }
                    </div>
                )
            });
            
        }

        setTabs(tabsDef);
    }, [aadAuthToken, addKeywordsProps, chats, currentAgentName, loadingChats, agent, tokenManager, userHasRootAccess, selectedSearchAgent, searchToolOptions, costs]);


    useEffect(() => {
        if (agent)
            setShowDebugInfo(agent.ShowDebugInfo);
        else
            setShowDebugInfo(false);
    }, [agent]);

    const getAgent = useCallback(async () => {
        if (currentAgentName && tokenManager) {
            const aadToken = await tokenManager.getAADToken();
            await getAgentDetails(aadToken, currentAgentName).then((data) => {
                setAgent(data);
            });
        }
        else {
            setAgent(null);
        }
    }, [currentAgentName, setAgent, tokenManager]);

    useEffect(() => {
        getAgent();
    }, [getAgent]);



    useEffect(() => {
        setPlaybackManager(new PlaybackManager(speechsdk, tokenManager));
    }, [tokenManager]);

    useEffect(() => {
        instance.handleRedirectPromise().then(async (result) => {
            await onAuthenticated();
        });
    }, [instance, onAuthenticated]);

    instance.handleRedirectPromise().catch((error) => {
        if (error.errorMessage !== 'User cancelled the flow') {
            console.log(error);
        }
    });

    const setupVoiceRecognition = useCallback(
        async () => {
            if ((recognizer.current === null || recognizerTokenExpiry.current === null || (recognizer.current && recognizerTokenExpiry.current && Date.now() > recognizerTokenExpiry.current)) && tokenManager) {
                const tokenObj = await tokenManager.getSpeechAPIToken();

                if (tokenObj != null) {
                    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
                    speechConfig.speechRecognitionLanguage = 'en-US';
                    speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "10000");

                    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
                    recognizer.current = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
                    recognizerTokenExpiry.current = Date.now() + 300000;
                }
            }
        }, [tokenManager]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;

            const runSetupVoiceRecognition = async () => {
                setupVoiceRecognition();
            };
            runSetupVoiceRecognition();
        }
    }, [playbackManager, setupVoiceRecognition]);


    function loadAgents(token, localTokenManager) {
        var agentsPromise = getAgents(token);
        agentsPromise.then(async (agentsData) => {
            if (agentsData) {
                var agents = agentsData.map(a => { return { value: a.value, label: a.value }; });

                setAgents(agents);
            }
            else {
                setAgents(null);
                localTokenManager.expireCachedToken();
                const hasAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, '4417bd63-4c77-4ebd-aaa0-67da1b95d0d3');
                setUserHasAccess(hasAccessGroup);
            }
            setLoadingAgentList(false);
        });
    }

    async function sttFromMic(readyCallback, resultCallback, agentName, chatId) {
        await setupVoiceRecognition();

        recognizer.current.sessionStarted = (s, e) => {
            readyCallback(true);
        };

        var text = "";

        await new Promise((resolve) => {
            recognizer.current.recognizeOnceAsync(result => {
                readyCallback(false);

                if (result.reason === ResultReason.RecognizedSpeech) {
                    text = result.text;
                } else {
                    console.info('ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.');
                }

                resolve();
            });
        });

        return text;
    }



    const wakeKeywordModelPath = `${process.env.PUBLIC_URL}/sydney_keyword.table`;

    //Note, this doesn't work as the SDK doesn't support this yet. https://learn.microsoft.com/en-us/azure/ai-services/speech-service/custom-keyword-basics?pivots=programming-language-javascript
    async function wakeOnKeyword(readyCallback, resultCallback) {
        const tokenObj = await tokenManager.getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';

        // Create a keyword recognition model from the specified file
        const model = await speechsdk.KeywordRecognitionModel.fromFile(wakeKeywordModelPath);

        // Create an audio configuration that captures audio from the default microphone
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

        // Create a speech recognizer using the subscription key, service region, and audio configuration
        const recognizer = new speechsdk.SpeechRecognizer(
            speechConfig,
            audioConfig
        );

        // Start keyword recognition and wait for the result
        recognizer.recognizeOnceAsync(
            model,
            result => {
                // Check the result reason
                if (result.reason === speechsdk.ResultReason.RecognizedKeyword) {
                    // Keyword was recognized, do something here
                    console.log(`Recognized keyword: ${result.text}`);
                } else {
                    // Keyword was not recognized, handle error here
                    console.error(`Error: ${result.errorDetails}`);
                }

                // Close the recognizer
                recognizer.close();
            },
            error => {
                // Handle error here
                console.error(`Error: ${error}`);
                recognizer.close();
            }
        );
    }


    async function handleAgentChange(value) {
        if (tokenManager) {
            setIsLoadingChats(true);
            console.log(`selected ${value}`);
            setcurrentAgentName(value);

            const token = await tokenManager.getAADToken();
            if (token) {
                var chats = await getAllChats(token, value)
                setMenuOptions(chats);
            }
            setIsLoadingChats(false);
        }
    };

    const handleLogout = () => {
        instance.logout();
        setAadAuthToken(null);
    };




    function setMenuOptions(chats) {
        if (chats) {
            var menuChats = chats.map(a => {

                var title = a.title;
                if (a.Title !== null && a.Title !== undefined) {
                    title = a.Title;
                }

                var created = a.created;
                if (a.Created !== null && a.Created !== undefined) {
                    created = a.Created;
                }

                var chatId = a.chatId;
                if (a.ChatId !== null && a.ChatId !== undefined) {
                    chatId = a.ChatId;
                }

                if (title) {
                    return {
                        title: `${title}-${new Date(created).toLocaleDateString('en-GB')} ${new Date(created).toLocaleTimeString()}`,
                        label: `${title}`,
                        key: chatId
                    };
                }
                return undefined;
            }
            );

            menuChats = menuChats.filter(item => item !== undefined);
            setChats(menuChats);
        }
    }

    function costsUpdated(costs) {
        setCosts(costs);
    }

    function updateChatList(chats) {
        setMenuOptions(chats);
    }

    function onAddAgent() {
        setEditAgentMode("add");
        setOpenDataConfig(true);
    }

    async function onSavedAgent() {
        setOpenDataConfig(false);
        loadAgents(await tokenManager.getAADToken(), tokenManager);
        await getAgent();

    }

    const menuTrigger = (
        <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined style={{ fontSize: 25, color: '#ffffff', }} /> : <MenuFoldOutlined style={{ fontSize: 25, color: '#ffffff', }} />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
                color: '#ffffff',
                //fontSize: '30px',
                //width: 100,
                //height: 100,
            }}
        />
    );

    function onMenuCollapse(e) {
        setCollapsed(e);
    }

    const onUserMenuClick = (e) => {
        switch (e.key) {
            case "Logout":
                handleLogout();
                break;
            case "Login":
                instance.loginRedirect();
                break;
            default:
                break;
        }
    };

    const userMenuItems = [
        {
            //label: accounts && accounts.length ? accounts[0].name : 'User',
            key: 'SubMenu',
            icon: <UserOutlined style={{ fontSize: 30 }} />,
            children: [
                {
                    label: 'Logout',
                    key: 'Logout',
                },
                {
                    label: 'API Version: ' + apiVersion,
                    key: 'APIVersion'
                }
            ]
        }
    ];

    const loggedoutUserMenuItems = [
        {
            key: 'Login',
            icon: <UserOutlined style={{ fontSize: 30 }} />,

        }
    ];


    return (
        /*<Div100vh>*/
        <Layout>
            <Header style={headerStyle}>

                <img src="Solera.png" alt="Solera" className="logo" /><div class="title">{currentAgentName}</div>

                {/*<div>*/}
                {/*    {window.env.REACT_APP_API_URL}*/}
                {/*</div>*/}

                <AuthenticatedTemplate>
                    <div class="user-detail">
                        <Menu mode="horizontal" items={userMenuItems} style={{ width: 80, marginTop: 10 }} onClick={onUserMenuClick} />
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div class="user-detail">
                        <Menu mode="horizontal" items={loggedoutUserMenuItems} style={{ width: 80, marginTop: 10 }} onClick={onUserMenuClick} />
                    </div>

                    {/*<div class="user-detail">*/}
                    {/*    <button className="link-button" onClick={() => instance.loginRedirect()}>Login</button>*/}
                    {/*</div>*/}
                </UnauthenticatedTemplate>
                {/*                <button className="link-button" onClick={handleCheckClaims}>Check Claims</button>*/}
            </Header>

            <AuthenticatedTemplate>
                {
                    aadAuthToken ? (
                        userHasAccess ? (
                            <Layout hasSider>
                                <Sider style={siderStyle} width={siderWidth} className="side-panel" collapsible onCollapse={onMenuCollapse} collapsedWidth={0} trigger={menuTrigger}>
                                    <Spin spinning={loadingAgentList} >
                                        <Space wrap>
                                            <div>
                                                <Select options={agents} onChange={handleAgentChange} style={{ width: siderWidth - 60 }} placeholder="Select Agent">
                                                </Select>

                                                {userHasRootAccess ? (
                                                    <Button onClick={onAddAgent}>+</Button>)
                                                    : (<></>)}

                                            </div>
                                            <br />

                                            <Tabs defaultActiveKey="1" items={tabs} type="card" theme="dark" className="tabs" >
                                            </Tabs>
                                        </Space>
                                    </Spin>
                                </Sider>

                                <Content style={contentStyle} className="main-content">
                                    <Spin tip="Loading" size="medium" spinning={loadingMessages} className="message-spinner" style={{ height: "100%" }} >
                                        <SydneyChatHub wakeOnKeyword={wakeOnKeyword} playbackManager={playbackManager} mic={sttFromMic} agentName={currentAgentName} loadedChatMessages={messages}
                                            tokenManager={tokenManager} loadedChatId={chatId} aadAuthToken={aadAuthToken} costsUpdated={costsUpdated} updateChatList={updateChatList} showDebugInfo={showDebugInfo} />
                                    </Spin>
                                </Content>

                                <AgentConfigurator msalInstance={instance} agentName={currentAgentName} visible={openDataConfig} tokenManager={tokenManager} disabled={!canEdit} mode={editAgentMode} onSaved={onSavedAgent} onCanceled={() => setOpenDataConfig(false)} />
                            </Layout>) :
                            (<div className="LoginPrompt">You do not have access to this application. Please contact your administrator.</div>)
                    ) : (<div className="LoginPrompt">Please Login</div>)
                }


            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="LoginPrompt">Please Login</div>
            </UnauthenticatedTemplate>

        </Layout >


    );
}